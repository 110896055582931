import { SvgIcon, SvgIconProps } from '@mui/material';

const WarningTriangleIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 36 32">
            <path d="M34.9988,26.676 L20.7338,2.14 C20.4586,1.659 20.0605,1.259 19.5801,0.983 C19.0997,0.706 18.5544,0.562 18.0001,0.564954067 C17.4457,0.562 16.9004,0.706 16.42,0.983 C15.9397,1.259 15.5415,1.659 15.2663,2.14 L1.0013,26.676 C0.7172,27.157 0.5674,27.704 0.5674,28.262 C0.5674,28.821 0.7172,29.368 1.0013,29.849 C1.2769,30.333 1.6764,30.735 2.1588,31.014 C2.6412,31.293 3.1892,31.438 3.7463,31.4350456 L32.2651,31.4350456 C32.8221,31.436 33.3696,31.29 33.852,31.011 C34.3344,30.733 34.7346,30.331 35.012,29.848 C35.2895,29.365 35.4345,28.818 35.4321293,28.261 C35.4298,27.704 35.2803,27.157 34.9988,26.676 Z M19.7776,10.566 L19.3613,21.141 L16.6726,21.141 L16.2451,10.566 L19.7776,10.566 Z M18.0001,27.137 C17.7601,27.157 17.5186,27.126 17.291,27.048 C17.0634,26.969 16.8546,26.844 16.6776,26.681 C16.5007,26.518 16.3594,26.319 16.2628,26.099 C16.1662,25.878 16.1164,25.64 16.1164,25.399 C16.1164,25.159 16.1662,24.92 16.2628,24.7 C16.3594,24.479 16.5007,24.281 16.6776,24.118 C16.8546,23.955 17.0634,23.83 17.291,23.751 C17.5186,23.673 17.7601,23.642 18.0001,23.661 C18.2401,23.642 18.4815,23.673 18.7091,23.751 C18.9367,23.83 19.1456,23.955 19.3225,24.118 C19.4995,24.281 19.6407,24.479 19.7373,24.7 C19.8339,24.92 19.8838,25.159 19.8838,25.399 C19.8838,25.64 19.8339,25.878 19.7373,26.099 C19.6407,26.319 19.4995,26.518 19.3225,26.681 C19.1456,26.844 18.9367,26.969 18.7091,27.048 C18.4815,27.126 18.2401,27.157 18.0001,27.137 Z"></path>
        </SvgIcon>
    );
};

export default WarningTriangleIcon;
